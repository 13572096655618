@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR&display=swap');

* {
    font-family: "IBM Plex Sans KR", sans-serif;
    font-weight: 400;
    font-style: normal;
    --cardbg:white;
}

body{
    margin: 0;
    background-color: #ececf5;
    display: flex;
    justify-content: center;
}


@media (prefers-color-scheme: dark) {
    body {
        background: #202b38 !important;
    }

    .card {
        color: #dbdbdb;
        background: #202b38 !important;
    }

    h1, h2, h3, p, dl, div, label {
        color: #dbdbdb !important;
    }

    a {
        color: #41adff;
    }

    code {
        color: #ffbe85;
        background: #161f27;
    }
}
